<template>
  <button class="uk-button uk-button-primary" @click="addUrlBanner">Добавить баннер по ссылке</button>
  <ul uk-accordion>
    <li v-for="(banner, key) in banners">
      <a class="uk-accordion-title" href="#">{{ banner.name }} <span v-if="hasErrors[key]" class="uk-badge"
                                                                     style="background:red;">{{ hasErrors[key] }}</span></a>

      <div class="uk-accordion-content">
        <div>
          <button class="uk-close" @click="deleteBanner(key)" uk-close type="button"></button>
          <div class="uk-flex uk-flex-center">
            <a v-if="banner.full_path" :href="banner.full_path" target="_blank">
              <div class="uk-display-block"
                   v-bind:style="{width: '70px', height: '70px', 'background-size': 'cover', background: 'no-repeat center/80% url(' + banner.full_path +')'}">

              </div>
            </a>
          </div>

          <div class="uk-card uk-card-body">
            <div class="uk-child-width-1-2@m uk-grid">
              <div>

                <div class="uk-margin">
                  <label class="uk-form-label">Название</label>
                  <input class="uk-input" type="text" v-model="banner.name">
                  <div class="" v-if="errors[`banners.${key}.name`]">
                    <span class="uk-text-danger" v-for="error in errors[`banners.${key}.name`]">{{ error }}</span>
                  </div>
                </div>

                <div class="uk-margin">
                  <label class="uk-form-label">Алиас</label>
                  <input class="uk-input" type="text" v-model="banner.alias">
                  <div class="" v-if="errors[`banners.${key}.alias`]">
                    <span class="uk-text-danger" v-for="error in errors[`banners.${key}.alias`]">{{ error }}</span>
                  </div>
                </div>

                <div class="uk-margin">
                  <label class="uk-form-label">Подпись (alt)</label>
                  <div class="uk-form-controls">
                    <input class="uk-input" type="text" v-model="banner.alt">
                  </div>
                  <div class="" v-if="errors[`banners.${key}.alt`]">
                    <span class="uk-text-danger" v-for="error in errors[`banners.${key}.alt`]">{{ error }}</span>
                  </div>
                </div>

                <div class="uk-margin">
                  <label class="uk-form-label">Ссылка на изображение</label>
                  <div class="uk-form-controls">
                    <input class="uk-input" type="text" v-model="banner.img_path">
                  </div>
                  <div class="" v-if="errors[`banners.${key}.img_path`]">
                    <span class="uk-text-danger" v-for="error in errors[`banners.${key}.img_path`]">{{ error }}</span>
                  </div>
                </div>
              </div>

              <div>

                <div class="uk-margin">
                  <label class="uk-form-label">Дата начала действия</label>
                  <div class="uk-form-controls">
                    <input class="uk-input" type="datetime-local" v-model="banner.date_start">
                  </div>
                  <div class="" v-if="errors[`banners.${key}.date_start`]">
                    <span class="uk-text-danger" v-for="error in errors[`banners.${key}.date_start`]">{{ error }}</span>
                  </div>
                </div>

                <div class="uk-margin">
                  <label class="uk-form-label">Дата конца действия</label>
                  <div class="uk-form-controls">
                    <input class="uk-input" type="datetime-local" v-model="banner.date_end">
                  </div>
                  <div class="" v-if="errors[`banners.${key}.date_end`]">
                    <span class="uk-text-danger" v-for="error in errors[`banners.${key}.date_end`]">{{ error }}</span>
                  </div>
                </div>

                <div class="uk-margin">
                  <label class="uk-form-label">Размеры баннера</label>
                  <div class="uk-form-controls">
                    <SizesSelect v-model="banner.sizes"></SizesSelect>
                    <div class="" v-if="errors[`banners.${key}.sizes`]">
                      <span class="uk-text-danger" v-for="error in errors[`banners.${key}.sizes`]">{{ error }}</span>
                    </div>
                  </div>
                  <div class="uk-margin">
                    <label class="uk-form-label">Ссылка баннера (куда ведет)</label>
                    <div class="uk-form-controls">
                      <input class="uk-input" type="text" v-model="banner.url">
                    </div>
                    <div class="" v-if="errors[`banners.${key}.url`]">
                      <span class="uk-text-danger" v-for="error in errors[`banners.${key}.url`]">{{ error }}</span>
                    </div>
                  </div>
                </div>

                <div class="uk-margin">
                  <label><input class="uk-checkbox" type="checkbox" v-model="banner.is_show"> Показывать</label>
                </div>

              </div>
            </div>
          </div>
        </div>


      </div>
    </li>
  </ul>
</template>

<script lang="ts">
import {computed, defineComponent, toRef} from "vue";
import Loading                            from '@/components/Loading.vue';
import SizesSelect                        from "../banner-size/SizesSelect.vue";

export default defineComponent({
  name      : "BannerEditableList",
  components: {
    Loading    : Loading,
    SizesSelect: SizesSelect,
  },
  emits     : ['update:modelValue'],
  props     : {
    modelValue: {
      type: Array,
    },
    errors    : {
      type: Object
    }
  },
  setup(props, vm) {
    const modelValue = toRef(props, 'modelValue');
    const errors     = toRef(props, 'errors');

    // @ts-ignore
    const banners = computed({
      get: () => modelValue.value,
      set: (val: any) => {
        vm.emit('update:modelValue', val);
      }
    })

    const haveBanners = computed(() => {
      // @ts-ignore
      return modelValue.value.length > 0
    })

    const addUrlBanner = () => {
      // @ts-ignore
      banners.value.push({
        name      : 'Новый баннер',
        alias     : '',
        alt       : '',
        date_start: null,
        date_end  : null,
        is_show   : true,
        url       : '',
        img_path  : null,
        fileIndex : null,
        sizes     : [],
      })
    }


    // @ts-ignore
    const hasErrors = computed(() => {
      let err = {};

      // @ts-ignore
      for (const [key] of Object.entries(errors.value)) {
        //@ts-ignore
        let matches = key.match('^banners\.([0-9]+)\.[a-z0-9\_]+$');
        if (matches && matches.length > 1) {
          // @ts-ignore
          err.hasOwnProperty(matches[1]) ? err[matches[1]]++ : err[matches[1]] = 1;
        }
      }

      return err;
    })

    const deleteBanner = (key: number) => {
      // @ts-ignore
      banners.value = banners.value.filter((v, k) => {
        return k !== key
      })
    }

    return {banners, haveBanners, addUrlBanner, deleteBanner, errors, hasErrors};
  },
})
</script>

<style scoped>
</style>
