
import {computed, defineComponent, toRef} from "vue";
import Loading                            from '@/components/Loading.vue';
import SizesSelect                        from "../banner-size/SizesSelect.vue";

export default defineComponent({
  name      : "BannerEditableList",
  components: {
    Loading    : Loading,
    SizesSelect: SizesSelect,
  },
  emits     : ['update:modelValue'],
  props     : {
    modelValue: {
      type: Array,
    },
    errors    : {
      type: Object
    }
  },
  setup(props, vm) {
    const modelValue = toRef(props, 'modelValue');
    const errors     = toRef(props, 'errors');

    // @ts-ignore
    const banners = computed({
      get: () => modelValue.value,
      set: (val: any) => {
        vm.emit('update:modelValue', val);
      }
    })

    const haveBanners = computed(() => {
      // @ts-ignore
      return modelValue.value.length > 0
    })

    const addUrlBanner = () => {
      // @ts-ignore
      banners.value.push({
        name      : 'Новый баннер',
        alias     : '',
        alt       : '',
        date_start: null,
        date_end  : null,
        is_show   : true,
        url       : '',
        img_path  : null,
        fileIndex : null,
        sizes     : [],
      })
    }


    // @ts-ignore
    const hasErrors = computed(() => {
      let err = {};

      // @ts-ignore
      for (const [key] of Object.entries(errors.value)) {
        //@ts-ignore
        let matches = key.match('^banners\.([0-9]+)\.[a-z0-9\_]+$');
        if (matches && matches.length > 1) {
          // @ts-ignore
          err.hasOwnProperty(matches[1]) ? err[matches[1]]++ : err[matches[1]] = 1;
        }
      }

      return err;
    })

    const deleteBanner = (key: number) => {
      // @ts-ignore
      banners.value = banners.value.filter((v, k) => {
        return k !== key
      })
    }

    return {banners, haveBanners, addUrlBanner, deleteBanner, errors, hasErrors};
  },
})
