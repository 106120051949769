import {ref}               from 'vue';
import {Api}               from "../../types/api/v1/electrification";
import Banner = Api.V1.Banner;
import ApiShowAction       from "../../services/api/ApiShowAction";
import BannerGroupUrlLoc   from "../../services/api/banner-group/BannerGroupUrlLoc";
import BannerGroupForm = Api.V1.BannerGroupForm;
import BannersUpdateAction from "../../services/api/banner/BannersUpdateAction";

export const useUploadImages = () => {
    const images = ref<Array<File | null>>([]);

    const currentImages = ref<FileList | null>(null);

    const banners = ref<Array<Banner>>([]);

    const groupId = ref<number | null>(null);

    const showAction = new ApiShowAction<BannerGroupForm>(new BannerGroupUrlLoc());

    const loadBanners = (value: number) => {
        return showAction.show(value).then((groupForm: { data: BannerGroupForm }) => {
            // @ts-ignore
            banners.value = groupForm.data.banners;
            images.value  = [];
            // @ts-ignore
            groupId.value = value;
        });
    }


    const addImages = (add: FileList | null) => {
        console.log(images.value);
        // @ts-ignore
        for (let i = 0; i < add.length; i++) {
            // @ts-ignore
            images.value.push(add.item(i));
        }

        //@ts-ignore
        let startIndex = images.value.length - add.length;
        bannersFromImages(startIndex);
    }

    const bannersFromImages = (startIndex = 0) => {
        // @ts-ignore
        for (let i = startIndex; i < images.value.length; i++) {
            // @ts-ignore
            banners.value.push({
                // @ts-ignore
                name      : images.value[i].name,
                alias     : '',
                alt       : '',
                date_start: null,
                date_end  : null,
                is_show   : true,
                url       : '',
                img_path  : null,
                fileIndex : i,
                sizes     : [],
            })
        }
    }


    const buildForm = () => {

        let formData = new FormData();

        for (let i = 0; i < banners.value.length; i++) {
            if (banners.value[i].hasOwnProperty('fileIndex') && banners.value[i].fileIndex !== null && images.value[i] !== null) {
                let index = banners.value[i].fileIndex;
                // @ts-ignore
                formData.append(`files[${index}]`, <Blob>images.value[index]);
            }
        }

        let fixedBanners = JSON.parse(JSON.stringify(banners.value));

        fixedBanners.map((value: Banner) => {
            // @ts-ignore
            value.sizes = value.sizes.map((size) => size.id);
        })

        formData.append("banners", JSON.stringify(fixedBanners));

        return formData;
    }

    const updateAction = new BannersUpdateAction();


    const saveErrors = ref({});
    const save       = () => {
        if (groupId.value === null) {
            return Promise.resolve(false);
        }

        updateAction.update(groupId.value, buildForm()).then(() => {
            groupId.value && loadBanners(groupId.value);
            saveErrors.value = {};
            return Promise.resolve(true);
        }, (error) => {
            saveErrors.value = error.response.data.errors;
            return Promise.resolve(false);
        })
    }


    return {images, banners, addImages, currentImages, loadBanners, save, saveErrors};
};