
import {defineComponent} from 'vue';
// @ts-ignore
import Multiselect       from "vue-multiselect";

export default defineComponent({
  name      : 'DropdownMenu',
  emits     : ['update:modelValue'],
  props     : {
    modelValue: null,
  },
  components: {Multiselect},
  setup(props, vm) {
    const onFileChange = (e: Event) => {
      let target: HTMLInputElement = <HTMLInputElement>e.target;
      // @ts-ignore
      vm.emit('update:modelValue', target.files);
    }
    return {onFileChange}
  },
});
