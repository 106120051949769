
import {defineComponent, onMounted} from "vue";
import Loading                      from '@/components/Loading.vue';
import SitesSelect                  from "../banner-site/SitesSelect.vue";
import BannerImageUploader          from "./BannerImageUploader.vue";
import {useUploadImages}            from "../../logic/banner/banner-upload-images";
import BannerEditableList           from "./BannerEditableList.vue";
import {useRoute, useRouter}        from "vue-router";
import {useBannerGroup}             from "../../logic/banner/banner-group";

export default defineComponent({
  name      : "BannerGroupCreate",
  components: {
    Loading            : Loading,
    SitesSelect        : SitesSelect,
    BannerImageUploader: BannerImageUploader,
    BannerEditableList : BannerEditableList,
  },
  setup() {
    const {images, banners, addImages, currentImages, loadBanners, save: saveBanners, saveErrors} = useUploadImages()
    // @ts-ignore
    const {group, nameErrors, sitesErrors, aliasErrors, save: saveGroup, setGroupId} = useBannerGroup();

    const route  = useRoute();
    const router = useRouter()

    onMounted(() => {
      // @ts-ignore
      loadBanners(route.params.requestId).then(null, () => {
        router.push({'name': 'banner-groups'})
      });
      // @ts-ignore
      setGroupId(route.params.requestId);
    })

    return {
      group, aliasErrors, nameErrors, sitesErrors, saveGroup,
      images, banners, addImages, currentImages, saveBanners, saveErrors
    };
  },
})
