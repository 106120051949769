import {reactive, ref} from 'vue';
import {Api}            from "../../types/api/v1/electrification";
import LaravelIndexResponse = Api.V1.LaravelIndexResponse;
import BannerSize = Api.V1.BannerSize;
import BannerSizeUrlLoc from "../../services/api/banner-size/BannerSizeUrlLoc";
import ApiSearcher      from "../../services/api/ApiSearcher";
import BannerSizeFilter = Api.V1.BannerSizeFilter;
import LaravelSearchRequest = Api.V1.LaravelSearchRequest;

export const useSearch = () => {
    const sizes      = ref<Array<BannerSize>>([]);
    const sizesReady = ref<boolean>(true);
    const request = reactive<LaravelSearchRequest<BannerSizeFilter>>({
        filter: {},
        sort: null,
        include : null,
        page: 1,
    });

    const fetcher = new ApiSearcher<BannerSize, BannerSizeFilter>(new BannerSizeUrlLoc());

    // @ts-ignore
    const setSizes = (value: Array<BannerSize>) => sizes.value = value;

    const searchSizes = () => {
        return fetcher.index(request).then((data: LaravelIndexResponse<BannerSize>) => {
            setSizes(data.data);
            sizesReady.value = true;
        });
    }


    const searchByName = (query: string) => {
        // @ts-ignore
        request.filter.size = query;

        return searchSizes();
    }

    return {sizes, sizesReady, request, setSizes, searchSizes, searchByName};
};