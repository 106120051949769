
import {defineComponent, onMounted, toRef, toRefs, computed} from 'vue';
// @ts-ignore
import Multiselect from "vue-multiselect";
import {useSearch} from "../../logic/banner-size/banner-size-search";
import {Api}            from "../../types/api/v1/electrification";
import BannerSite = Api.V1.BannerSite;

export default defineComponent({
  name      : 'DropdownMenu',
  emits     : ['update:modelValue'],
  props     : {
    modelValue: {
      type: Array
    },
  },
  components: {Multiselect},
  setup(props, vm) {
    const {sizes, sizesReady, request, setSizes, searchByName} = useSearch();

    const modelValue = toRef(props, 'modelValue');

    // @ts-ignore
    const selected = computed({
      get: () => modelValue.value,
      set: (val: Array<BannerSite>) => {
        vm.emit('update:modelValue', val);
      }
    })

    onMounted(() => searchByName(''))

    return {...toRefs(request), sizes, sizesReady, setSizes, searchByName, selected}
  },
});
