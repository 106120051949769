
import {AxiosInstance, AxiosResponse} from "axios";
import ApiClient                      from "../../client/ApiClient";

export default class BannersUpdateAction{
    private _client: AxiosInstance;

    constructor() {
        this._client = ApiClient.init();
    }

    public update(groupId: number, model: FormData): Promise<AxiosResponse> {
        return this._client.post(this.getUrl(groupId), model)
    }


    protected getUrl(groupId: number): string {
        return '/banner-group/add-banners/' + groupId
    }
}