<template>

  <div uk-grid>
    <div class="uk-width-auto@m">
      <ul class="uk-tab-left" uk-tab="connect: #component-tab-left; animation: uk-animation-fade">
        <li><a href="#">Группа</a></li>
        <li><a href="#">Баннеры</a></li>
      </ul>
    </div>
    <div class="uk-width-expand@m">
      <ul id="component-tab-left" class="uk-switcher">
        <li>
          <form @submit.prevent class="uk-form-stacked">
            <div class="uk-margin">
              <label class="uk-form-label">Название</label>
              <input class="uk-input" type="text" v-model="group.name">
              <div class="" v-if="nameErrors">
                <span class="uk-text-danger" v-for="error in nameErrors">{{ error }}</span>
              </div>
            </div>


            <div class="uk-margin">
              <label class="uk-form-label">Алиас</label>
              <input class="uk-input" type="text" v-model="group.alias">
              <div class="" v-if="aliasErrors">
                <span class="uk-text-danger" v-for="error in aliasErrors">{{ error }}</span>
              </div>
            </div>

            <div class="uk-margin">
              <label class="uk-form-label">Сайт проекта</label>
              <SitesSelect v-model="group.sites"></SitesSelect>
              <div class="" v-if="sitesErrors">
                <span class="uk-text-danger" v-for="error in sitesErrors">{{ error }}</span>
              </div>
            </div>

            <button class="uk-button uk-button-primary" @click="saveGroup">Сохранить</button>
          </form>
        </li>
        <li>
          <div class="uk-margin">
            <label class="uk-form-label">Загрузка изображений</label>
            <BannerImageUploader v-model="currentImages" @update:model-value="addImages"></BannerImageUploader>
          </div>

          <div class="uk-margin">
            <button class="uk-button uk-button-primary uk-margin-right" @click="saveBanners">Сохранить</button>
            <BannerEditableList v-model="banners" :errors="saveErrors"></BannerEditableList>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, onMounted} from "vue";
import Loading                      from '@/components/Loading.vue';
import SitesSelect                  from "../banner-site/SitesSelect.vue";
import BannerImageUploader          from "./BannerImageUploader.vue";
import {useUploadImages}            from "../../logic/banner/banner-upload-images";
import BannerEditableList           from "./BannerEditableList.vue";
import {useRoute, useRouter}        from "vue-router";
import {useBannerGroup}             from "../../logic/banner/banner-group";

export default defineComponent({
  name      : "BannerGroupCreate",
  components: {
    Loading            : Loading,
    SitesSelect        : SitesSelect,
    BannerImageUploader: BannerImageUploader,
    BannerEditableList : BannerEditableList,
  },
  setup() {
    const {images, banners, addImages, currentImages, loadBanners, save: saveBanners, saveErrors} = useUploadImages()
    // @ts-ignore
    const {group, nameErrors, sitesErrors, aliasErrors, save: saveGroup, setGroupId} = useBannerGroup();

    const route  = useRoute();
    const router = useRouter()

    onMounted(() => {
      // @ts-ignore
      loadBanners(route.params.requestId).then(null, () => {
        router.push({'name': 'banner-groups'})
      });
      // @ts-ignore
      setGroupId(route.params.requestId);
    })

    return {
      group, aliasErrors, nameErrors, sitesErrors, saveGroup,
      images, banners, addImages, currentImages, saveBanners, saveErrors
    };
  },
})
</script>
