<template>
    <div class="uk-placeholder uk-text-center uk-margin-remove-top">
      <div class="uk-form-custom uk-display-block">
        <input type="file" multiple @change="onFileChange">
        <p class="uk-margin-remove uk-display-block"><span class="uk-link">Выберите баннеры</span></p>
      </div>
    </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
// @ts-ignore
import Multiselect       from "vue-multiselect";

export default defineComponent({
  name      : 'DropdownMenu',
  emits     : ['update:modelValue'],
  props     : {
    modelValue: null,
  },
  components: {Multiselect},
  setup(props, vm) {
    const onFileChange = (e: Event) => {
      let target: HTMLInputElement = <HTMLInputElement>e.target;
      // @ts-ignore
      vm.emit('update:modelValue', target.files);
    }
    return {onFileChange}
  },
});
</script>